import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';
import { Button, Row } from 'react-bootstrap';

import './index.scss';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { AdsenseAd } from '../../modules/common/components/adsense-ad';

const LCIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page'} game="lc">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Limbus Company database and wiki</h1>
        <h2>
          Prydwen.gg is a database and wiki for Limbus Company, a turn-based
          strategy RPG Gacha Game developed and published by Project Moon.
        </h2>
      </div>
      <SectionHeader title="Play Limbus Company on PC!" />
      <div className="banner-limbus ">
        <div className="cta">
          <h4>Reroll and play Limbus Company on PC!</h4>
          <Link to="/limbus-company/play-on-pc">
            <Button variant="primary">Learn more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/limbus-company/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/limbuscompany/categories/category_intro.jpg"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll"
          link="/limbus-company/guides/reroll"
          image={
            <StaticImage
              src="../../images/limbuscompany/categories/category_reroll.jpg"
              alt="Reroll"
            />
          }
          updated
        />
        <CategoryCard
          title="Beginner guide"
          link="/limbus-company/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/limbuscompany/categories/category_beg.jpg"
              alt="Beginner"
            />
          }
        />
        <CategoryCard
          title="Beginner team ideas"
          link="/limbus-company/guides/beginner-team-ideas"
          image={
            <StaticImage
              src="../../images/limbuscompany/categories/category_teamb.png"
              alt="Beginner team ideas"
            />
          }
        />
      </Row>
      <AdsenseAd />
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default LCIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Limbus Company Wiki & Database | Prydwen Institute"
    description="Prydwen.gg is a database and wiki for Limbus Company, a turn-based strategy RPG Gacha Game developed and published by Project Moon."
    game="limbus"
  />
);
